import React, {useEffect, useState} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Switch from "react-switch";
import axios from "axios";
import {toast} from "react-toastify";

const SensorsListModal = (props) => {
    const [checked, setChecked] = useState(false)
    const handleChange = (e, id) => {
        console.log(e.target.name)
        console.log(id)
        let nim = null
        nim = props.divicesensors.indexOf(id)
        // props.divicesensors.filter(item => item === id ?
            if(nim > -1) {
                // props.divicesensors.splice(nim - 1, 1);

                props.setDeviceSensors(props.divicesensors.filter(item => item !== id))
                setForCheck({...forCheck, [e.target.name]: false})
            } else {
                setForCheck({...forCheck, [e.target.name]: true})
                props.divicesensors.push(id)
            }
        console.log(props.divicesensors)
        nim = null
    }
    const [forCheck, setForCheck] = useState({
        w1: false,
        w2: false,
        w3: false,
        w4: false,
        w5: false,
        w6: false,
        w12: false,
        w13: false,
        w15: false,
        w16: false,
    })
    const changeSensors = () => {
      axios.post("https://monitor.bgsoft.uz/api2/index.php?mod=updatedevicesensor&id=" + props.device_id, {
          "sensors": props.divicesensors
      })
          .then(res =>{
              toast.success("Успешно сохранено")
              props.setViewDevice(false)
              props.setDeviceSensors([])
          })
    }
    return (
        <Modal className="custom-modal " size="xl" isOpen={props.viewDevice} toggle={() => props.setViewDevice(false)}>

            <ModalHeader toggle={() => props.setViewDevice(false)}>
                <p></p>
            </ModalHeader>
            <ModalBody>
                <h3 className="mb-3">Добавить новое устройство</h3>
                {
                    props.sensors?.map((item, index) =>  (
                        <div className="row mb-3">
                            <div className="col-md-8"><h5>{item?.name}</h5></div>
                            <div className="col-md-4">

                                <input type="checkbox" className="switch" name={"w" + item.id}  onChange={ (e) => handleChange(e, item.id)}
                                       checked={
                                    props.divicesensors?.filter(item2 => item2 === item.id)?.length > 0
                                 }
                                />
                            </div>
                        </div>
                    ))
                }
            </ModalBody>
            <ModalFooter>
                <div className="btn btn-danger " onClick={() => props.setViewDevice(false)}
                     style={{paddingLeft: "15px", paddingRight: "15px"}}>НЕТ
                </div>
                <div className="btn btn-success " onClick={changeSensors}>ДА</div>
            </ModalFooter>
        </Modal>
    );
};

export default SensorsListModal;