import React, {useState} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Map, Placemark, YMaps} from "@pbe/react-yandex-maps";
import axios from "axios";
import {toast} from "react-toastify";

const CreateDeviceModal = (props) => {
    const [createData, setCreateData] = useState({
        name: "",
        adres: "",
        sn: "",
        type: ""
    })

    const changeValue = (e) => {
      // console.log(e.target.name)
      // console.log(e.target.value)
        setCreateData({...createData, [e.target.name]: e.target.value})
        console.log(createData)
        console.log(props.getLoc)

    }

    const sendData = () => {
        const Data = new FormData()
        Data.append('name' , createData.name)
        Data.append('adres' , createData.adres)
        Data.append('sn' , createData.sn)
        Data.append('type' , createData.type)
        Data.append('lat' , props.getLoc[0])
        Data.append('lng' , props.getLoc[1])

        axios.post("https://monitor.bgsoft.uz/api2/index.php?mod=adddevice", Data)
            .then(res => {
                props.setdevice_id(res.data.device_id)
                props.setAddDevice(false)
                props.setViewDevice(true)
                props.getDevice()
            })
            .catch(err => toast.error("Error"))
    }
    return (
        <Modal className="custom-modal " size="xl" isOpen={props.addDevice}  toggle={() => props.setAddDevice(false)}>

            <ModalHeader toggle={() => props.setAddDevice(false)}>
                <p></p>
            </ModalHeader>
            <ModalBody>
                <h3>Добавить новое устройство</h3>
                <div className="row">
                    <div className="col-md-6 mb-2">
                        <label className="form-label">Именование</label>
                        <input onChange={(e) => changeValue(e)} type="text" name="name" className="form-control"/>
                    </div>
                    <div className="col-md-6 mb-2">
                        <label className="form-label">Адрес</label>
                        <input onChange={(e) => changeValue(e)} type="text" name="adres" className="form-control"/>
                    </div>
                    <div className="col-md-6 mb-2">
                        <label className="form-label">Серийный номер</label>
                        <input onChange={(e) => changeValue(e)} type="text" name="sn" className="form-control"/>
                    </div>
                    <div className="col-md-6 mb-2">
                        <label className="form-label">Тип</label>
                        <input onChange={(e) => changeValue(e)} type="text" name="type" className="form-control"/>
                    </div>

                </div>

                <h5 className="mt-3 mb-3">Выбрать на карте </h5>
                <YMaps     >
                    <Map defaultState={{
                        center: [41.311158, 69.279737],
                        zoom: 13,

                    }}
                         onClick={props.onMapClick}
                         width='100%'
                         height='360px'>

                        <Placemark geometry={props.getLoc}
                        />
                    </Map>
                </YMaps>
                <h5 >{props.getLoc[0] ? props.getLoc[0] +  " - " + props.getLoc[1] : <span className="text-danger">Координата не выбрана</span>}</h5>
            </ModalBody>
            <ModalFooter>
                <div className="btn btn-danger " onClick={() => props.setAddDevice(false)} style={{paddingLeft: "15px", paddingRight: "15px"}}>НЕТ</div>
                <div className="btn btn-success " onClick={sendData} >ДА</div>
            </ModalFooter>
        </Modal>
    );
};

export default CreateDeviceModal;