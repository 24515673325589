import React, {useEffect, useState} from 'react';
import AlarmList from "./AlarmList";
import axios from 'axios'
import {Map, YMaps, Placemark} from '@pbe/react-yandex-maps';
import HomeModal from "./HomeModal";
import CreateDevice from "./CreateDevice";
import {FullscreenControl, GeolocationControl, SearchControl, ZoomControl} from "react-yandex-maps";

const Home = () => {
    const [myModal, setMyModal] = useState(false)
    const [allData, setAllData] = useState([])
    const [selectData,setSelectData] = useState('')
    const [selectDataObject,setSelectDataObject] = useState([])
    const OpenModalFc = (id) => {
        setSelectData(id)
        setMyModal(true)
        axios.get("https://monitor.bgsoft.uz/api2/index.php?mod=getdevice&id=" + id)
            .then(res =>{
                setSelectDataObject(res.data)
                // console.log(res.data)
            })
    }
    useEffect(() =>{
        axios.get("https://monitor.bgsoft.uz/api2/index.php?mod=getdevices")
            .then(res =>{
                setAllData(res.data)

            })
    }, [])
    return (
        <div className="p-0 m-0 full-map-main">
            <div className="row m-0 p-0 d-flex">
                <div className="alarm-list-left">
                    <AlarmList />
                </div>
                <div className="full-map w-100  ">
                    <YMaps     >
                        <Map defaultState={{
                            center: [41.311158, 69.279737],
                            zoom: 13,
                        }}   width='100%'
                             height='calc(100vh - 60px)'>
                            {allData?.map((item, index) =>(
                                    <Placemark
                                        // style={{background: 'red'}}
                                        geometry={[item?.center_latlng[0], item?.center_latlng[1]]}
                                        options={{
                                            iconLayout: "default#image",
                                            iconImageHref: item?.icon,
                                            iconImageSize: [32, 32],
                                        }}
                                        onClick={() => OpenModalFc(item.id)}
                                    />
                            ))}

                        </Map>
                    </YMaps>
                </div>
            </div>
            {/*<CreateDevice />*/}

            <HomeModal
                setMyModal={setMyModal}
                myModal={myModal}
                selectData={selectData}
                selectDataObject={selectDataObject}
            />
        </div>
    );
};

export default Home;