import React from 'react';
import Home from "./components/Home";
import MainNavbar from "./components/MainNavbar";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {ToastContainer} from "react-toastify";
import DeviceList from "./components/DeviceList";
import Test from "./components/Test";
import DeletedList from "./components/DeletedList";

const App = () => {
    return (
       <BrowserRouter>
           <MainNavbar />
           <Switch>

               <Route exact path="/" component={Home} />
               <Route exact path="/device-list" component={DeviceList} />
               <Route exact path="/deleted-list" component={DeletedList} />
               <Route exact path="/test" component={Test} />

           </Switch>
           <ToastContainer
               // position="bottom-right"
               theme="colored"

           />
       </BrowserRouter>
    );
};

export default App;