import React, {useEffect, useState} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Map, Placemark, YMaps} from "@pbe/react-yandex-maps";
import axios from "axios";
import {toast} from "react-toastify";

const EditDeviceModal = (props) => {
    const [createData, setCreateData] = useState({
        name: "",
        adres: "",
        sn: "",
        type: ""
    })

    const changeValue = (e) => {
        // console.log(e.target.name)
        // console.log(e.target.value)
        props.setdevice_edit_data({...props.device_edit_data, [e.target.name]: e.target.value})
        console.log(createData)

    }

    const sendData = () => {
        const Data = new FormData()
        Data.append('name' , props?.device_edit_data.name)
        Data.append('adres' , props?.device_edit_data.adres)
        Data.append('sn' , props?.device_edit_data.sn)
        Data.append('type' , props?.device_edit_data.type)
        Data.append('lat' , props?.getLoc2[0])
        Data.append('lng' , props?.getLoc2[1])

        axios.post("https://monitor.bgsoft.uz/api2/index.php?mod=editdevice&id=" + props?.device_edit_id, Data )
            .then(res => {
                // props?.setdevice_id(res.data.device_id)
                // props?.setViewDevice(true)
                props?.getDevice()
                props?.setEditDeviceModal(false)
            })
            .catch(err => toast.error("Error"))
    }
    useEffect(() => {
        setCreateData(props?.device_edit_data)


    }, [props?.editDeviceModal]);
    return (
        <Modal className="custom-modal " size="xl" isOpen={props?.editDeviceModal}  toggle={() => props?.setEditDeviceModal(false)}>

            <ModalHeader toggle={() => props?.setEditDeviceModal(false)}>
                <p></p>
            </ModalHeader>
            <ModalBody>
                <h3>устройство</h3>
                <div className="row">
                    <div className="col-md-6 mb-2">
                        <label className="form-label">Именование</label>
                        <input value={props?.device_edit_data?.name} onChange={(e) => changeValue(e)} type="text" name="name" className="form-control"/>
                    </div>
                    <div className="col-md-6 mb-2">
                        <label className="form-label">Адрес</label>
                        <input value={props?.device_edit_data?.adres} onChange={(e) => changeValue(e)} type="text" name="adres" className="form-control"/>
                    </div>
                    <div className="col-md-6 mb-2">
                        <label className="form-label">Серийный номер</label>
                        <input value={props?.device_edit_data?.sn} onChange={(e) => changeValue(e)} type="text" name="sn" className="form-control"/>
                    </div>
                    <div className="col-md-6 mb-2">
                        <label className="form-label">Тип</label>
                        <input value={props?.device_edit_data?.type} onChange={(e) => changeValue(e)} type="text" name="type" className="form-control"/>
                    </div>

                </div>

                <h5 className="mt-3 mb-3">Выбрать на карте </h5>
                <YMaps     >
                    <Map defaultState={{
                        center: [41.311158, 69.279737],
                        zoom: 13,

                    }}
                         onClick={props?.onMapClick2 }
                         width='100%'
                         height='360px'>

                        <Placemark geometry={props.getLoc2}
                        />
                    </Map>
                </YMaps>
                <h5 >{props?.getLoc2[0] ? props?.getLoc2[0] +  " - " + props?.getLoc2[1] : <span className="text-danger">Координата не выбрана</span>}</h5>
            </ModalBody>
            <ModalFooter>
                <div className="btn btn-danger " onClick={() => props?.setEditDeviceModal(false)} style={{paddingLeft: "15px", paddingRight: "15px"}}>НЕТ</div>
                <div className="btn btn-success " onClick={sendData} >ДА</div>
            </ModalFooter>
        </Modal>
    );
};

export default EditDeviceModal;