import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {toast} from "react-toastify";

const DeletedList = () => {
    const [allData, setAllData] = useState([])
    const [viewDevice, setViewDevice] = useState(false)
    const [deleteDeviceId, setDeleteDeviceId] = useState(null)

    const openModal = (id) => {
        setDeleteDeviceId(id)
        setViewDevice(true)
    }
    const recoveryDevice = () => {
        axios.get("https://monitor.bgsoft.uz/api2/index.php?mod=recoverydevice&id=" + deleteDeviceId)
            .then(res =>{
                getData()
                setViewDevice(false)
                toast.success("Успешно")
            })
    }
    const getData = () => {
        axios.get("https://monitor.bgsoft.uz/api2/index.php?mod=getdevicesinactive")
            .then(res => {
                setAllData(res.data)
            })
    }
    useEffect(() =>{
        getData()
    }, [])
    return (
        <div className="device-list">
            <div className="d-flex w-100">
                <div className="left" >

                </div>
                <div className="right  ">
                    <table className="table w-100 ">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>name</th>
                            <th>status</th>
                            <th>statuserror</th>
                            <th>type</th>
                            <th>location</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            allData?.map((item, index) => (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{item?.name}</td>
                                    <td>{item?.status}</td>
                                    <td>{item?.statuserror}</td>
                                    <td>{item?.type}</td>
                                    <td>{item?.center_latlng[0] + " , " + [item?.center_latlng[1]]}</td>
                                    <td className="btn-group">
                                        <button className="btn btn-primary change-btn " onClick={  () => openModal(item?.id)}><img src="./img/recovery-arrow.png" alt="recovery-arrow"/></button>
                                    </td>
                                </tr>
                            ))
                        }

                        </tbody>
                    </table>
                </div>
            </div>
            <Modal size="xs" isOpen={viewDevice}  toggle={() => setViewDevice(false)}>
                <ModalHeader toggle={() => setViewDevice(false)}>
                    <p></p>
                </ModalHeader>
                <ModalBody>
                    <h4 className="mt-3 mb-3">Вы уверены, что хотите вернуть это?</h4>
                </ModalBody>
                <ModalFooter>
                    <div className="btn btn-danger " onClick={() => setViewDevice(false)} style={{paddingLeft: "15px", paddingRight: "15px"}}>НЕТ</div>
                    <div className="btn btn-success " onClick={recoveryDevice}>ДА</div>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default DeletedList;