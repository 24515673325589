import React, {useState} from 'react';
import {Link} from "react-router-dom";

const MainNavbar = () => {
    const [sideOpen, setSideOpen] = useState(false)

    return (
        <div className="mainNavbar-fh">
            <div className="mainNavbar">
                <div className="logo">
                    <button className="create-device " onClick={() => setSideOpen(!sideOpen)}><img src="/img/menu.png" alt=""/></button>

                </div>
                <div className="nav-center">

                </div>
                <div className="nav-end">

                </div>
            </div>
            {
                sideOpen ?
                    <div className="main-side">
                        <div className="side-list">
                            <ul>
                                <li><Link to="/">Главный</Link></li>
                                <li><Link to="/device-list">Список</Link></li>
                                <li><Link to="/deleted-list">Удаленный список</Link></li>
                            </ul>
                        </div>
                    </div>
                    :
                    ""
            }
        </div>
    );
};

export default MainNavbar;