import React, {useEffect, useState} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import axios from "axios";
import {toast} from "react-toastify";

const HomeModal = (props) => {
    const [yesModal, setYesModal] = useState(false)
    const [releId, setReleId] = useState(null)
    const restartRele = (devId) => {
      axios.get("https://monitor.bgsoft.uz/alarm/relay.php?device_id=" + devId + "&relay_id=" + releId + "&status=1")
          .then(res =>{
              toast.success("Произошла перезагрузка")
              setYesModal(false)
          })
          .catch(err =>{
              toast.error("Ошибка")
          })
    }

    const openReleModal = (id) => {
        setReleId(id)
        setYesModal(true)
    }

    return (
       <>

           <Modal className="custom-modal " size="xl" isOpen={props.myModal}  toggle={() => props.setMyModal(false)}>

               <div className="row m-0 pb-5 ">
                   <ModalHeader toggle={() => props.setMyModal(false)}>
                       <p>Подробная информация о шкафе</p>
                   </ModalHeader>
                   <ModalBody>
                       <div className="row">
                           <div className="col-md-4">
                               <h5>Объект: <span className="ml-3 fw-bold"> {props?.selectDataObject?.name}</span></h5>
                               <h5>Манзил: <span className="pl-2 fw-bold">{props?.selectDataObject?.manzil}</span></h5>

                               <div>
                                   <button className="btn btn-primary p-4 w-100 mt-3" onClick={() => openReleModal(1)}>Перезагрузка 1 РЕЛЕ (Сфетофор)</button>
                                   <button className="btn btn-primary p-4 w-100 mt-3" onClick={() => openReleModal(2)}>Перезагрузка 2 РЕЛЕ (Маршрутизатор)</button>
                                   <button className="btn btn-primary p-4 w-100 mt-3" onClick={() => openReleModal(3)}>Перезагрузка 3 РЕЛЕ (БП)</button>
                               </div>
                           </div>
                           <div className="col-md-8">
                               <h5>Состояния шкафа</h5>
                               <div className="row">
                                   {
                                       props?.selectDataObject?.sensors?.map((item, index) =>(
                                           <div className="col-md-4 pt-3 pb-3">
                                               <div className={ item.sensor_status === 2 ? "alarm-box  bg-danger ": item.sensor_status === 1 ? "alarm-box  bg-warning " : item.sensor_status === 0 ? "alarm-box bg-success" : "" }>
                                                   <h6>{item?.sensor_name}</h6>
                                                   <h5>{item?.sensor_status_name}</h5>
                                                   <h4>{item?.sensor_value === null ? "" : item?.sensor_value}</h4>
                                                   <h6>{item?.sensor_last_check_date.slice(0, -3)}</h6>
                                                   <img src="./img/fire.png" alt="" className="alarm-img"/>
                                               </div>
                                           </div>
                                       ))
                                   }
                               </div>

                           </div>
                       </div>
                   </ModalBody>
               </div>

           </Modal>
           <Modal className="custom-modal " size="md" isOpen={yesModal}  toggle={() => setYesModal(false)}>

                   <ModalHeader toggle={() => setYesModal(false)}>
                        <p></p>
                   </ModalHeader>
                   <ModalBody>
                       <h3>Вы уверены, что хотите перезагрузиться?</h3>
                   </ModalBody>
               <ModalFooter>
                   <div className="btn btn-success " onClick={() => restartRele(props.selectData)} style={{paddingLeft: "15px", paddingRight: "15px"}}>ДА</div>
                   <div className="btn btn-danger " onClick={() => setYesModal(false)} style={{paddingLeft: "15px", paddingRight: "15px"}}>НЕТ</div>
               </ModalFooter>
           </Modal>
       </>
    );
};

export default HomeModal;