import React, { useState, useEffect } from 'react';
import {Map, Placemark, YMaps} from "react-yandex-maps";

function AlarmList() {
    const [message, setMessage] = useState({});

    useEffect(() => {
        const socket = new WebSocket('wss://monitor.bgsoft.uz:2346');

        socket.onmessage = (event) => {
            setMessage(JSON.parse(event.data));
            console.log(event.data)
        };

        return () => {
            socket.close();
        };
    }, []);

    useEffect(()=>{
        console.log(message)
    }, [message])
    return (
        <>

            {
                message?.last_state?.map((item, index) =>(
                    <div className='mini-border-keys'>
                        <h5><b>Объект:</b> {item?.device_name} </h5>
                        <h5><b>Қурилма рақами:</b> {item?.device_id} </h5>
                        <h5 className="mb-4"><b> Шкаф тури:</b> Сфетофор Коммутатор </h5>
                        {item?.list?.map((item2, index2) =>(
                            <div className="mt-2 bg-danger p-3 rounded">
                                <span className="text-white d-block">Датчик: </span>
                                <span className="text-white d-block">Қайд қилинган сана: {item2.datetime}</span>
                                <span className="text-white d-block">Ҳолат: {item2?.statuserrorname}</span>
                            </div>
                        ))}
                    </div>
                ))
            }



        </>
    );
}

export default AlarmList;