import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Map, Placemark, YMaps} from "@pbe/react-yandex-maps";
import CreateDeviceModal from "./CreateDeviceModal";
import SensorsListModal from "./SensorsListModal";
import {toast} from "react-toastify";
import EditDeviceModal from "./EditDeviceModal";

const DeviceList = () => {
    const [allData, setAllData] = useState([])
    const [getLoc, setGetLoc] = useState([])
    const [getLoc2, setGetLoc2] = useState([])
    const [viewData, setViewData] = useState([])
    const [addDevice, setAddDevice] = useState(false)
    const [deleteDevice, setDeleteDevice] = useState(false)
    const [viewDevice, setViewDevice] = useState(false)
    const [editDeviceModal, setEditDeviceModal] = useState(false)
    const [deleteDeviceId, setDeleteDeviceId] = useState(null)
    const [device_id, setdevice_id] = useState(null)
    const [device_edit_id, setdevice_edit_id] = useState(null)
    const [device_edit_data, setdevice_edit_data] = useState({
        name: "",
        adres: "",
        sn: "",
        type: "",
        lat: "",
        lng: ""
    })
    const [sensors, setSensors] = useState([])
    const [divicesensors, setDeviceSensors] = useState([])
   const  onMapClick = (e) => {
       setGetLoc(e.get("coords"))

    };
    const  onMapClick2 = (e) => {
        setGetLoc2(e.get("coords"))

    };
   const  deleteDevFc = (e) => {
       setDeleteDeviceId(e)
       setDeleteDevice(true)
    };
   const  viewDevFc = (e) => {
       setdevice_id(e)
       axios.get("https://monitor.bgsoft.uz/api2/index.php?mod=getdevicesensors&id=" + e)
           .then(res =>{
               setDeviceSensors(res.data)
           })
       setViewDevice(true)
    };
const editDevice = (id) => {
    setdevice_edit_id(id)
    setEditDeviceModal(true)

    axios.get("https://monitor.bgsoft.uz/api2/index.php?mod=getdevice&id=" + id)
            .then(res =>{
                setdevice_edit_data(res.data)
                setdevice_edit_id(id)
                setGetLoc2([res.data.lat, res.data.lng])

            })
}
   const deleteDeviceWithId = () => {
       axios.get("https://monitor.bgsoft.uz/api2/index.php?mod=deletedevice&id=" + deleteDeviceId)
           .then(res =>{
               setDeleteDevice(false)
               getDevice()
               toast.success("Удалено успешно")
           })
   }
   const getDevice = () => {
       axios.get("https://monitor.bgsoft.uz/api2/index.php?mod=getdevices")
           .then(res =>{
               setAllData(res.data)
           })
   }
    const getSensors = () => {
        axios.get("https://monitor.bgsoft.uz/api2/index.php?mod=sensorslist")
            .then(res =>{
                setSensors(res.data)
            })
    }

    useEffect(() =>{
        getDevice()
        getSensors()
        setGetLoc([])
    }, [addDevice, editDeviceModal])
    return (
        <div className="device-list">
            <div className="d-flex w-100">
                <div className="left" >

                </div>
                <div className="right  ">

                    <button className="add-new-device btn btn-primary mb-4" onClick={() => setAddDevice(true)}>
                          +  Добавить новое
                    </button>
                    <table className="table w-100 ">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>name</th>
                            <th>status</th>
                            <th>statuserror</th>
                            <th>type</th>
                            <th>location</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            allData?.map((item, index) => (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{item?.name}</td>
                                    <td>{item?.status}</td>
                                    <td>{item?.statuserror}</td>
                                    <td>{item?.type}</td>
                                    <td>{item?.center_latlng[0] + " , " + [item?.center_latlng[1]]}</td>
                                    <td className="btn-group">
                                        <button className="btn btn-success change-btn" onClick={  () => viewDevFc(item?.id)}><img src="./img/view.png" alt="view"/></button>
                                        <button className="btn btn-primary change-btn" onClick={() => editDevice(item?.id)}><img src="./img/pen.png" alt="edit"/></button>
                                        <button className="btn btn-danger change-btn " onClick={  () => deleteDevFc(item?.id)}><img src="./img/delete.png" alt="delete"/></button>
                                    </td>
                                </tr>
                            ))
                        }

                        </tbody>
                    </table>
                </div>
            </div>
            <CreateDeviceModal
                addDevice={addDevice}
                setAddDevice={setAddDevice}
                onMapClick={onMapClick}
                getLoc={getLoc}
                getDevice={getDevice}
                setViewDevice={setViewDevice}
                setdevice_id={setdevice_id}
            />
            <SensorsListModal
                setViewDevice={setViewDevice}
                viewDevice={viewDevice}
                viewData={viewData}
                device_id={device_id}
                setdevice_id={setdevice_id}
                sensors={sensors}
                divicesensors={divicesensors}
                setDeviceSensors={setDeviceSensors}
            />
            <EditDeviceModal
                device_edit_data={device_edit_data}
                setdevice_edit_data={setdevice_edit_data}
                device_edit_id={device_edit_id}
                setEditDeviceModal={setEditDeviceModal}
                editDeviceModal={editDeviceModal}
                getLoc2={getLoc2}
                getDevice={getDevice}
                onMapClick2={onMapClick2}

            />
            <Modal size="xs" isOpen={deleteDevice}  toggle={() => setDeleteDevice(false)}>
                <ModalHeader toggle={() => setDeleteDevice(false)}>
                    <p></p>
                </ModalHeader>
                <ModalBody>
                    <h4 className="mt-3 mb-3">Вы уверены, что хотите это удалить?</h4>
                </ModalBody>
                <ModalFooter>
                    <div className="btn btn-danger " onClick={() => setDeleteDevice(false)} style={{paddingLeft: "15px", paddingRight: "15px"}}>НЕТ</div>
                    <div className="btn btn-success " onClick={deleteDeviceWithId}>ДА</div>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default DeviceList;